import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'


import axios from 'axios'
import VueAxios from 'vue-axios'


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css' 

export default createVuetify({
    icons: {
        defaultSet: 'mdi',
    },
})

const vuetify = createVuetify({
    components,
    directives,
})


createApp(App).use(vuetify).use(router).use(VueAxios, axios).mount('#app')