<template>
  <div class="home">

    <v-container>
      <h1>Kantine plan uge {{ week }}, {{ year }}</h1>

      <v-col cols="auto">
        <v-btn elevation="4" @click="week -= 1">
          << </v-btn>

            <v-btn elevation="4"
              @click="week = this.getWeekNumber(new Date())[1]; year = this.getWeekNumber(new Date())[0]">Denne
              uge</v-btn>

            <v-btn elevation="4" @click="week += 1">>></v-btn>
      </v-col>

      <v-row>
        <v-col v-for="menu_item in menu" cols="12" xl="3" lg="4" md="4" sm="6" xs="6">
          <v-card>

            <v-img height="150px" :src="'data:image;base64,' + menu_item.imageblob" cover></v-img>

            <v-card-title>
              {{ menu_item.name }}
            </v-card-title>

            <v-card-subtitle class="mb-2">
              {{ menu_item.orderDeadlineText }}
            </v-card-subtitle>

          </v-card>
        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data: () => ({
    loading: true,
    week: 0,
    year: 0,
    menu: {

    }
  }),
  methods: {
    getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo];
    },
    getMenu() {
      this.axios.get("http://foodandco.ramgaard.net/api/get_menu.php?week=" + this.week + "&year=" + this.year).then((response) => {

        this.menu = response.data;

        this.loading = false;
      })
    }
  },
  mounted() {
    this.week = this.getWeekNumber(new Date())[1];
    this.year = this.getWeekNumber(new Date())[0];
    this.loading = true;
  },
  watch: {
    '$data.week': {
      handler: function () {
        if (this.week <= 0) {
          this.year -= 1;
          this.week = 53;
        }
        if (this.week >= 54) {
          this.year += 1;
          this.week = 1;
        }
        this.getMenu();
      },
      deep: true
    }
  }
}
</script>